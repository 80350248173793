<template>
  <div>
    <div v-if="info != null" class="detail hidden-xs-only">
      <div class="top">
        <div class="container flex row-between">
          <div class="left">
            <h2>{{ info.positionName }}</h2>
            <p>
              <!-- <span v-if="info.salary != '0'">
                月薪{{ info.salary.split(",")[0] }}k ~
                {{ info.salary.split(",")[1] }}k ·
              </span> -->
              {{ info.city }} · {{ info.duration }} ·
              {{ educations[info.education] }}
            </p>
          </div>
          <div class="right">
            <div class="btn" @click="copy">
              <img
                src="@/assets/images/fenxiang.png"
                alt=""
                width="22"
                height="22"
              />
              转发
            </div>
            <div class="btn-1" @click="handleApply(1)">申请职位</div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <div class="left">
          <div class="left-top">
            <div class="row">
              <!-- <div class="title">招聘人数：</div> -->
              <div class="">
                <!-- <span class="title-2">招聘人数： </span>
                <span> {{ info.recruits_num }}人</span> -->
                <span class="title-2">招聘类型： </span>
                <span> {{ states[info.type_id] }}</span>
                <span class="title-2 m-l-24">工作地点： </span>
                <span>{{ info.city }}</span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="title">招聘类型：</div>
              <span class="type">{{ states[info.type_id] }}</span>
            </div>
            <div class="row">
              <div class="title">工作地点：</div>
              <div class="title-1">{{ info.city }}</div>
            </div> -->
          </div>
          <div class="m-t-16">
            <div class="title">岗位职责：</div>
            <div class="content" v-html="info.responsibility">
              <!-- {{ info.responsibility }} -->
            </div>
          </div>
          <div class="m-t-16">
            <div class="title">任职要求：</div>
            <div class="content" v-html="info.requirements">
              <!-- {{ info.requirements }} -->
            </div>
          </div>
        </div>
        <div v-if="recoms.length > 0" class="right">
          <div class="title">职位推荐：</div>
          <ul>
            <li class="item" v-for="item in recoms" :key="item.id">
              <!-- <div class="title" @click="goRedirect(item.id)">
                {{ item.positionName }}
              </div> -->
              <el-link type="primary" @click="goRedirect(item.id)">{{
                item.positionName
              }}</el-link>
              <!-- <div class="address">{{ item.city }}</div> -->
            </li>
          </ul>
        </div>
      </div>
      <el-dialog
        title="申请职位"
        center
        :visible.sync="dialogVisible"
        width="600px"
        @close="handleClose('form')"
      >
        <el-form ref="form" :model="form" size="mini" :rules="rules">
          <el-form-item
            label="投递人身份："
            :label-width="formLabelWidth"
            prop="is_recommend"
          >
            <el-radio-group v-model="form.is_recommend" @change="onChange">
              <el-radio :label="0">本人投递简历</el-radio>
              <el-radio :label="1">内推简历</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="求职者姓名："
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              class="w370"
              v-model="form.name"
              placeholder="请输入姓名"
              maxlength="10"
              show-word-limit
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            v-if="form.is_recommend == 1"
            label="内推员工邮箱："
            :label-width="formLabelWidth"
            prop="re_email"
          >
            <el-input
              class="w370"
              v-model="form.re_email"
              placeholder="请输入员工邮箱"
              show-word-limit
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            label="求职者手机号："
            :label-width="formLabelWidth"
            prop="tel"
          >
            <el-input
              class="w370"
              v-model="form.tel"
              autocomplete="off"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item label="求职者简介：" :label-width="formLabelWidth">
            <el-input
              v-model="form.introduce"
              class="w370"
              type="textarea"
              rows="8"
              placeholder="请输入求职者简介"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item
            label="上传简历："
            :label-width="formLabelWidth"
            prop="file_url"
          >
            <div>
              <div class="flex">
                <el-input
                  style="width: 270px"
                  v-model="fileName"
                  autocomplete="off"
                  placeholder="请上传简历"
                  disabled
                />
                <el-button
                  class="rela"
                  type="danger"
                  style="margin-left: 20px; width: 80px"
                  :loading="loading"
                >
                  <el-input
                    class="input"
                    v-model="form.file_url"
                    type="file"
                    id="file"
                    autocomplete="off"
                    placeholder="请上传简历"
                    @change="
                      (res) => {
                        getFile(res, 1);
                      }
                    "
                  />
                  上传
                </el-button>
              </div>
              <p class="prompt w370">
                *支持pdf、doc、docx、ppt、pptx、png、jpg、jpeg格式简历文件，文件大小不超过20M
              </p>
            </div>
          </el-form-item>
          <el-form-item label="" :label-width="formLabelWidth">
            <div class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="danger" @click="submitForm('form')"
                >申请职位
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        title="清微智能网站"
        center
        :visible.sync="loginVisible"
        width="410px"
        @close="handleClose('loginForm')"
      >
        <el-form
          ref="loginForm"
          :model="loginForm"
          size="mini"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="手机号：" prop="tel" :error="errorTel">
            <el-input
              v-model="loginForm.tel"
              placeholder="请输入手机号"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <div class="flex">
              <el-input
                v-model="loginForm.code"
                autocomplete="off"
                placeholder="请输入验证码"
              />
              <el-button
                class="m-l-24"
                size="mini"
                type="danger"
                @click="getCodeHandle"
                >{{ get_code_text }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
        <el-button
          type="danger"
          style="width: 100%"
          @click="handLogin('loginForm', 1)"
          >登录/注册</el-button
        >
        <el-checkbox class="m-t-24" v-model="checked" text-fill="#666666"
          >登录/注册即代表您阅读并同意
        </el-checkbox>
        <el-link type="primary" @click="goInest">《用户协议》 </el-link>
        <div class="login">
          <p>其他登录方式</p>
          <img src="@/assets/images/weixin.png" alt="" @click="wxlogin" />
        </div>
      </el-dialog>
    </div>
    <div class="hidden-sm-and-up">
      <van-nav-bar
        title="职位详情"
        left-arrow
        fixed
        placeholder
        @click-left="back"
        @click-right="onCopy"
      >
        <template #right>
          <img src="@/assets/images/share.png" alt="" width="20" />
        </template>
      </van-nav-bar>
      <div class="m-15" v-if="info != null">
        <h2 class="title-1">{{ info.positionName }}</h2>
        <p class="title-2">
          <!-- 月薪{{ info.salary.split(",")[0] }} - {{ info.salary.split(",")[1] }}k · -->
          {{ info.duration }} ·
          {{ educations[info.education] }}
        </p>
        <van-divider />
        <div class="grid">
          <!-- <div class="grid-item">
            <div class="title-3">招聘人数：</div>
            <div class="title">{{ info.recruits_num }}人</div>
          </div> -->
          <div class="grid-item">
            <div class="title-3">招聘类型：</div>
            <van-tag plain type="primary">{{ states[info.type_id] }}</van-tag>
          </div>
          <div class="grid-item">
            <div class="title-3">工作地点：</div>
            <div class="title">{{ info.city }}</div>
          </div>
        </div>
        <van-divider />
        <div class="">
          <div class="title-3">岗位职责：</div>
          <div class="content-1" v-html="info.responsibility" />
        </div>
        <van-divider />
        <div class="">
          <div class="title-3">任职要求：</div>
          <div class="content-1" v-html="info.requirements" />
        </div>
        <div v-if="recoms.length > 0" class="title-3 m-t-24">推荐职位</div>
      </div>

      <el-link
        class="block"
        v-for="item in recoms"
        :key="item.id"
        type="primary"
        @click="goRedirect(item.id)"
        >{{ item.positionName }}</el-link
      >

      <!-- <van-cell
        v-for="item in recoms"
        :key="item.id"
        is-link
        @click="goRedirect(item.id)"
      >
        <template #title>
          <div class="custom-title">{{ item.positionName }}</div>
        </template>
      </van-cell> -->
      <div style="height: 90px" />
      <div class="footer">
        <van-button
          class="button"
          type="danger"
          round
          block
          @click="handleApply(2)"
          >申请职位
        </van-button>
      </div>

      <van-dialog
        v-model="show1"
        title="清微智能网站"
        width="360"
        :showConfirmButton="false"
        :showCancelButton="false"
      >
        <div class="cross" @click="handleClose('loginForm')">
          <van-icon name="cross" />
        </div>
        <div class="m-15">
          <el-form
            ref="loginForm"
            :model="loginForm"
            size="mini"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="手机号：" prop="tel" :error="errorTel">
              <el-input
                v-model="loginForm.tel"
                placeholder="请输入手机号"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
              <div class="flex">
                <el-input
                  v-model="loginForm.code"
                  autocomplete="off"
                  placeholder="请输入验证码"
                />
                <el-button
                  class="m-l-24"
                  size="mini"
                  type="danger"
                  @click="getCodeHandle"
                  >{{ get_code_text }}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <van-button type="danger" block @click="handLogin('loginForm', 2)"
            >登录/注册
          </van-button>
          <el-checkbox class="m-t-24" v-model="checked" text-fill="#666666"
            >登录/注册即代表您阅读并同意
          </el-checkbox>
          <el-link type="primary" href="https://www.sinmore.com.cn/inest/9"
            >《用户协议》
          </el-link>
          <div class="login">
            <p>其他登录方式</p>
            <img src="@/assets/images/weixin.png" alt="" @click="h5login" />
          </div>
        </div>
      </van-dialog>

      <van-dialog
        v-model="show"
        title="申请职位"
        width="360"
        show-cancel-button
        :beforeClose="beforeClose"
      >
        <div class="m-15">
          <el-form
            ref="form"
            :model="form"
            size="mini"
            :rules="rules"
            label-position="top"
          >
            <el-form-item
              label="投递人身份："
              :label-width="formLabelWidth"
              prop="is_recommend"
            >
              <el-radio-group
                v-model="form.is_recommend"
                fill="red"
                @change="onChange"
              >
                <el-radio :label="0">本人投递简历</el-radio>
                <el-radio :label="1">内推简历</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="求职者姓名："
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                class="w370"
                v-model="form.name"
                placeholder="请输入姓名"
                maxlength="10"
                show-word-limit
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              v-if="form.is_recommend == 1"
              label="内推员工邮箱："
              :label-width="formLabelWidth"
              prop="re_email"
            >
              <el-input
                class="w370"
                v-model="form.re_email"
                placeholder="请输入员工邮箱"
                show-word-limit
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="求职者手机号："
              :label-width="formLabelWidth"
              prop="tel"
            >
              <el-input
                class="w370"
                v-model="form.tel"
                autocomplete="off"
                placeholder="请输入手机号"
              />
            </el-form-item>
            <el-form-item label="求职者简介：" :label-width="formLabelWidth">
              <el-input
                v-model="form.introduce"
                type="textarea"
                rows="4"
                placeholder="请输入求职者简介"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              label="上传简历："
              :label-width="formLabelWidth"
              prop="file_url"
            >
              <div>
                <div class="flex">
                  <el-input
                    class="w370"
                    v-model="fileName"
                    autocomplete="off"
                    placeholder="请上传简历"
                    disabled
                  />
                  <el-button
                    class="rela el-button-1"
                    type="danger"
                    :loading="loading"
                  >
                    <el-input
                      class="input-1"
                      v-model="form.file_url"
                      type="file"
                      id="file"
                      autocomplete="off"
                      @change="
                        (res) => {
                          getFile(res, 2);
                        }
                      "
                    />
                    <span class="text">上传</span>
                  </el-button>
                </div>
                <p class="text-1">
                  *支持pdf、doc、docx、ppt、pptx、png、jpg、jpeg格式简历文件，文件大小不超过20M
                </p>
              </div>
            </el-form-item>
            <!-- <el-form-item label="" :label-width="formLabelWidth" a>
              <div class="dialog-footer">
                <el-button @click="resetForm('form')">取 消</el-button>
                <el-button type="danger" @click="submitForm1('form')"
                  >确 定
                </el-button>
              </div>
            </el-form-item> -->
          </el-form>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  sms,
  info,
  login,
  ststoken,
  saveResume,
  recruitInfo,
} from "@/assets/js/api";
const OSS = require("ali-oss");
import { Toast } from "vant";
export default {
  data() {
    return {
      username: "",
      password: "",
      show: false,
      show1: false,
      errorTel: "",
      get_code_text: "获取验证码",
      loginVisible: false,
      checked: true,
      loginForm: {
        tel: "",
        code: "",
      },
      fileName: "",
      client: "",
      states: {
        0: "校园招聘",
        1: "社会招聘",
      },
      educations: {
        0: "本科及以上",
        1: "硕士及以上",
        2: "博士",
      },
      info: null,
      form: {
        tel: "",
        name: "", //姓名
        job_position_id: "", //职位ID
        recruitment_id: "", //招聘ID
        email: "",
        file_url: "", //简历地址
        introduce: "", //简介
        re_email: "", //推荐人EMAIL
        is_recommend: 0, //是否内推 0 否 1 是
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        file_url: [
          { required: true, message: "请上传简历", trigger: "change" },
        ],
        re_email: [
          { required: true, message: "请输入推荐人邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      id: this.$route.query.id,
      formLabelWidth: "140px",
      dialogVisible: false,
      recoms: [],
    };
  },
  created() {
    // let qrCode = this.$route.query.code;
    // let qrState = this.$route.query.id;
    // this.qrCode = qrCode;
    // if (!this.$cookies.get("token")) {
    //   if (this.qrCode) {
    //     this.id = qrState;
    //     if (sessionStorage.getItem("loginMethod") == "h5") {
    //       login({
    //         wxcodeh5: this.qrCode,
    //       }).then((res) => {
    //         // this.$message.success("登录成功");
    //         this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
    //       });
    //     }
    //   }
    // }
    this.getInfo();
    this.getStstoken();
  },
  methods: {
    goRedirect(id) {
      this.$router.replace({
        path: "/redirect",
        query: {
          id,
        },
      });
    },
    goInest() {
      window.open(`https://www.sinmore.com.cn/inest/9`, "_blank");
    },
    h5login() {
      sessionStorage.setItem("loginMethod", "h5");
      let local = encodeURIComponent(window.location.href); //获取当前url
      let appid = "wx95c437c12b79939e";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect#wechat_redirect`;
    },
    goDetail(id) {
      this.id = id;
      window.scrollTo(0, 0); // chrome
      document.body.scrollTop = 0; // firefox
      document.documentElement.scrollTop = 0; // safari
      this.getInfo();
    },
    wxlogin() {
      sessionStorage.setItem("loginMethod", "pc");
      let appId = "wxb77761cc82a3140e";
      let local = encodeURIComponent(window.location.href); //获取当前url
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_login&state=${this.id}#wechat_redirect`;
      // window.open(
      //   `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${this.id}#wechat_redirect`,
      //   "_blank"
      // );
    },
    back() {
      this.$router.go(-1);
    },
    onCopy() {
      let oInput = document.createElement("input");
      oInput.value = window.location.href;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast("职位链接复制成功");
      oInput.remove();
    },
    copy() {
      let oInput = document.createElement("input");
      oInput.value = window.location.href;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "职位链接复制成功",
        type: "success",
      });
      oInput.remove();
    },
    getRecoms() {
      info({
        type_id: 1,
        per_page: 999,
        type_id: this.info.type_id,
        position_top_id: this.form.position_top_id,
      }).then((res) => {
        this.recoms = res.data;
      });
    },
    onChange() {
      if (this.form.is_recommend == 0) {
        this.form.re_email = "";
      }
      this.$refs["form"].clearValidate(["file_url"]);
    },
    // 获取验证码
    getCodeHandle() {
      if (!this.loginForm.tel) {
        this.errorTel = "请输入手机号";
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
        this.errorTel = "手机号格式错误";
        return;
      }
      this.errorTel = "";
      let _this = this;
      if (this.get_code_text !== "获取验证码") return false;
      sms({
        tel: this.loginForm.tel,
      }).then((res) => {
        let num = 60;
        this.get_code_text = num + "s后重试";
        this.timer = setInterval(() => {
          num = num - 1;
          if (num <= 0) {
            clearInterval(_this.timer);
            _this.get_code_text = "获取验证码";
          } else {
            _this.get_code_text = num + "s后重试";
          }
        }, 1000);
      });
    },
    handleApply(action) {
      if (!this.$cookies.get("token")) {
        if (action == 1) {
          this.loginVisible = true;
        } else {
          this.show1 = true;
        }
      } else {
        if (action == 1) {
          this.dialogVisible = true;
        } else {
          this.show = true;
        }
      }
    },
    handLogin(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (!this.loginForm.tel) {
          this.errorTel = "请输入手机号";
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
          this.errorTel = "手机号格式错误";
          return;
        }
        if (valid) {
          login(this.loginForm).then((res) => {
            if (action == 1) {
              this.$message.success("登录成功");
            } else {
              Toast("登录成功");
            }
            this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
            this.handleClose(formName);
            this.$pubsub.publish("loginSuccess");
          });
        }
      });
    },
    handleClose(formName) {
      this.form.introduce = "";
      this.$refs[formName].clearValidate(["tel"]);
      this.resetForm(formName);
    },
    beforeClose(action, done) {
      if (action == "confirm") {
        this.submitForm1("form", done);
      } else {
        this.resetForm("form", done);
      }
    },
    submitForm1(formName, done) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.is_recommend == 1) {
            if (!this.form.re_email.toLowerCase().includes("tsingmicro.com")) {
              Toast("您不是公司员工，无法提交!");
              done(false);
              return;
            }
          }
          this.form.file_url = this.file_url;
          saveResume(this.form).then(
            (res) => {
              this.form.introduce = "";
              this.resetForm(formName, done);
              Toast("提交成功");
              this.$refs["form"].clearValidate(["file_url"]);
            },
            (err) => {
              done(false);
            }
          );
        } else {
          done(false);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.is_recommend == 1) {
            if (!this.form.re_email.toLowerCase().includes("tsingmicro.com")) {
              this.$message.error("您不是公司员工，无法提交!");
              return;
            }
          }
          this.form.file_url = this.file_url;
          saveResume(this.form).then((res) => {
            this.form.introduce = "";
            this.resetForm(formName);
            this.$message.success("提交成功");
          });
        }
      });
    },
    resetForm(formName, done) {
      this.$refs[formName].clearValidate(["file_url"]);
      this.$refs[formName].clearValidate(["tel"]);
      this.$refs[formName].resetFields();
      this.loginForm.tel = "";
      this.fileName = "";
      this.dialogVisible = false;
      this.loginVisible = false;
      this.show1 = false;
      if (done) {
        done();
      }
    },
    getStstoken() {
      ststoken().then((res) => {
        this.client = new OSS({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
          region: res.data.region_id,
          // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
          accessKeyId: res.data.Credentials.AccessKeyId,
          accessKeySecret: res.data.Credentials.AccessKeySecret,
          // 从STS服务获取的安全令牌（SecurityToken）。
          stsToken: res.data.Credentials.SecurityToken,
          // 填写Bucket名称。
          bucket: res.data.bucket,
        });
      });
    },
    async getFile(e, action) {
      const data = document.getElementById("file").files[0];
      let type = data.name.split(".")[1];
      let arr = ["pdf", "doc", "docx", "ppt", "pptx", "png", "jpg", "jpeg"];
      if (arr.includes(type)) {
        const file = document.getElementById("file").files[0];
        try {
          this.loading = true;
          const result = await this.client.put(file.name, file);
          this.file_url = result.url;
          this.fileName = file.name;
          this.loading = false;
          if (action == 1) {
            this.$message.success("上传成功");
          } else {
            Toast("上传成功");
          }
        } catch (e) {
          console.log(e, "ee");
          this.loading = false;
          if (action == 1) {
            this.$message.error("上传失败，请稍后再试");
          } else {
            Toast("上传失败，请稍后再试");
          }
        }
      } else {
        if (action == 1) {
          this.$message.error("文件格式错误");
        } else {
          Toast("上传失败，请稍后再试");
        }
      }
    },
    getInfo() {
      recruitInfo({
        id: this.id,
      }).then((res) => {
        this.info = res.data;
        console.log(this.info, "this.info");
        this.form.recruitment_id = this.info.id;
        this.form.job_position_id = this.info.job_position_id;
        this.form.position_top_id = this.info.position_top_id;
        this.getRecoms();
      });
    },
  },
};
</script>

<style>
ol li {
  list-style-type: decimal;

  list-style-position: inside;
}
</style>
<style lang="scss" scoped>
.content-1 {
  word-break: break-all;
  white-space: pre-wrap;
  font-size: 14px;
  color: #000;
  line-height: 28px;
}

.block {
  display: block;
  margin: 15px;
}
.login {
  margin-top: 24px;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    margin-bottom: 10px;
  }
  > img {
    cursor: pointer;
  }
}
.cross {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  z-index: 999999;
  font-size: 20px;
}
.text-1 {
  margin-top: 6px;
  line-height: 20px;
  font-size: 12px;
}
.input-1 {
  /deep/ .el-input__inner {
    opacity: 0 !important;
    width: 80px;
    height: 28px;
    position: absolute;
    left: -22px;
    top: -18px;
    z-index: 999;
  }
}
.el-button-1 {
  width: 80px;
  height: 28px;
  margin-left: 10px;
}
.text {
  position: absolute;
  left: 20px;
  top: 7px;
}
/deep/ .van-field {
  display: flex;
  flex-direction: column;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  background: #fff;
  .button {
    margin: 0 20px;
  }
}
.grid {
  display: flex;
  .grid-item {
    flex: 1;
  }
}

.title-3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.title-2 {
  font-size: 14px;
}
.title-1 {
  font-size: 20px;
  font-weight: bold;
}
/deep/ .van-nav-bar .van-icon {
  color: #fff;
  font-size: 20px;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/ .van-nav-bar {
  background-color: #cf322e;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #666666;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #666666;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #d0322e;
  background-color: #d0322e;
}
.detail {
  .input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    cursor: pointer;

    padding: 0 16px;
    font-size: 13px;
    border-radius: 4px;
    color: #dcdfe6;
    border: 0.5px solid #dcdfe6;

    .file {
      width: 300px;
      position: absolute;
      overflow: hidden; //超出一行文字自动隐藏
      text-overflow: ellipsis; //文字隐藏后添加省略号
      white-space: nowrap; //强制不换行
    }

    .el-icon-circle-close {
      position: absolute;
      right: 10px;
      top: 9px;
    }
  }

  .prompt {
    margin-top: 4px;
    font-size: 12px;
    color: #999999;
  }

  .el-button--danger {
    background-color: #cf322e;
  }

  .w370 {
    width: 370px;
  }

  .main-content {
    width: 1200px;
    margin: 48px auto;
    display: flex;

    .right {
      margin-left: 94px;

      .title {
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
      }

      .item {
        margin-top: 40px;

        .title {
          font-size: 20px;
          font-weight: normal;
        }

        .address {
          margin-top: 16px;
          font-size: 16px;
        }
      }
    }

    .left {
      width: 725px;

      .left-top {
        display: flex;
      }

      .row {
        margin-right: 80px;
        height: 50px;

        .title {
          font-size: 18px;
          font-weight: 600;
        }
        .title-2 {
          font-size: 18px;
          font-weight: 600;
        }
        .title-1 {
          font-size: 18px;
        }

        .type {
          padding: 8px 16px;
          border-radius: 2px;
          background: #f7f7f7;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .content {
        word-break: break-all;
        white-space: pre-wrap;
        font-size: 14px;
        color: #000;
        line-height: 28px;
      }
    }
  }

  .top {
    height: 180px;
    background: #f7f7f7;

    .left {
      padding-top: 90px;

      > h2 {
        font-size: 28px;
        font-weight: bold;
      }

      > p {
        margin-top: 8px;
        font-size: 16px;
        color: #666666;
      }
    }

    .right {
      padding-top: 53px;

      .btn {
        cursor: pointer;
        width: 170px;
        height: 49px;
        color: #cf322e;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #cf322e;

        > img {
          margin-right: 10px;
        }
      }

      .btn-1 {
        cursor: pointer;
        width: 170px;
        height: 49px;
        margin-top: 10px;
        color: #fff;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cf322e;
      }
    }
  }
}
</style>